import { graphql, useStaticQuery } from 'gatsby'

const useShopifyData = (mainProductId, variant, subscription) => {
  const {
    data: { products },
    sanityData: { sanityProducts },
  } = useStaticQuery(graphql`
    {
      data: allShopifyProduct(filter: { tags: { eq: "liveowyn.com" } }) {
        products: nodes {
          shopifyId
          variants {
            sku
            shopifyId
          }
        }
      }
      sanityData: allSanityProduct {
        sanityProducts: nodes {
          shopifyProductId
          shopifyBulkProductId
          variants {
            sku
            allowSubscriptions
          }
        }
      }
    }
  `)

  const sanityProduct = sanityProducts?.find(
    p => p.shopifyProductId === mainProductId || p.shopifyBulkProductId === mainProductId
  )

  const bulkPackProductId = sanityProduct ? sanityProduct.shopifyBulkProductId : null

  const skuToFind = variant?.sku

  const sanityVariant = sanityProduct?.variants?.find(v => v.sku === skuToFind)

  const variantFromMainProduct = findVariantFromProduct(
    products.find(
      ({ shopifyId }) =>
        extractNumericFromGID(shopifyId, 'Product') ===
        global.parseInt(sanityProduct?.shopifyProductId, 10)
    ),
    skuToFind,
    variant?.listPrice,
    variant?.listPrice
  )
  const variantFromBulkPackProduct = findVariantFromProduct(
    products.find(
      ({ shopifyId }) =>
        extractNumericFromGID(shopifyId, 'Product') === global.parseInt(bulkPackProductId, 10)
    ),
    skuToFind,
    variant?.discountedPrice,
    variant?.listPrice
  )

  const mainProduct = variantFromMainProduct || variantFromBulkPackProduct
  const bulkProduct = variantFromBulkPackProduct || variantFromMainProduct

  return subscription
    ? {
        ...mainProduct,
        alternativeVariantId: bulkProduct?.variantId,
        ...(sanityVariant || {}),
      }
    : {
        ...bulkProduct,
        alternativeVariantId: mainProduct?.variantId,
        ...(sanityVariant || {}),
      }
}

export default useShopifyData

const findVariantFromProduct = (product, skuToFind, price, listPrice) => {
  if (product && product.variants) {
    const variant = product.variants.find(({ sku }) => sku === skuToFind)
    if (variant) {
      return {
        variantId: extractNumericFromGID(variant.shopifyId, 'ProductVariant'),
        variantPrice: price,
        listPrice,
      }
    }
  }
  return null
}

export const extractNumericFromGID = (gid, type) => {
  let output
  if (typeof gid === 'number') return gid
  const startWithText = `gid://shopify/${type}/`
  if (gid.startsWith(startWithText)) output = gid.slice(startWithText.length)
  else if (!Number.isNaN(global.parseInt(gid, 10))) output = gid
  return global.parseInt(output, 10)
}
