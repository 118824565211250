import { keyframes } from '@emotion/react'
import Link from 'components/Link'
import PortableText from 'components/PortableText'
import { graphql, useStaticQuery } from 'gatsby'
import useLayout from 'hooks/useLayout'
import React, { useLayoutEffect, useRef, useState } from 'react'

import theme from '../styles/theme'

const NotificationBar = () => {
  const layout = useLayout()
  const { settings } = useStaticQuery(graphql`
    {
      settings: sanityNotificationBar {
        text: _rawText
        url
        enabled
      }
    }
  `)

  if (!layout.showHeader) {
    return null
  }

  if (!settings || !settings.text || !settings.enabled) {
    return null
  }

  const Tag = settings.url ? Link : 'div'

  const scroll = keyframes({
    '0%': { transform: 'translate3d(0, 0, 0)' },
    '100%': { transform: 'translate3d(-100%, 0, 0)' },
  })

  const msgRef = useRef()
  const [countMsgs, setCountMsgs] = useState()

  useLayoutEffect(() => {
    setCountMsgs(Math.ceil((window.innerWidth / msgRef.current.offsetWidth) * 2))
  }, [])

  return (
    <Tag
      to={settings.url}
      css={{
        width: '100%',
        height: '2rem',
        overflow: 'hidden',
        display: 'inline-flex',
        textDecoration: 'underline',
        color: theme.colors.spinachDark,
        transition: theme.globalTransition,
        backgroundColor: theme.colors.tumeric,
        '&[href]:hover div': {
          animationPlayState: 'paused',
        },
        p: {
          fontWeight: 700,
          margin: '0 15px',
          fontSize: '.875rem',
          display: 'inline-block',
        },
        div: {
          height: '100%',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          display: 'inline-flex',
          animation: `${scroll} 280s linear infinite`,
          [theme.mediumDown]: {
            animation: `${scroll} 150s linear infinite`,
          },
        },
      }}
    >
      {Array(2).fill(
        <div>
          {Array(countMsgs).fill(
            <div ref={msgRef}>
              <PortableText>{settings.text}</PortableText>
            </div>
          )}
        </div>
      )}
    </Tag>
  )
}

export default NotificationBar
