import { useLazyQuery, useMutation } from '@apollo/client'
import axios from 'axios'
import { fireAnalyticsEvent, fireCartUpdatedEvent } from 'lib/analytics'
import { ADD_CART_ITEM } from 'lib/graphql/addCartItems'
import { CREATE_CART } from 'lib/graphql/createCart'
import { GET_CART } from 'lib/graphql/getCart'
import { REMOVE_CART_ITEM } from 'lib/graphql/removeCartItems'
import { UPDATE_CART_DISCOUNT } from 'lib/graphql/updateCartDiscount'
import { UPDATE_LINE_ITEM } from 'lib/graphql/updateLineItem'
import { mapCartData } from 'lib/mapShopify'
import { getParameterByName, setCookie } from 'lib/storage'
import React, { useCallback, useEffect, useReducer, useState } from 'react'

export const StoreContext = React.createContext()

const reducer = (state, { action, value }) => {
  switch (action) {
    case 'setCart':
      if (state.cart.lineItems.length > 0 && value.lineItems.length === 0) {
        fireAnalyticsEvent({
          event: 'cartUpdated',
          SourceProductCategoryNumber: '',
          SourceProductNumber: '',
        })
      } else if (value.lineItems.length > 0) {
        fireCartUpdatedEvent(value)
      }
      return {
        cart: value,
        loading: false,
      }
    case 'loading':
      return {
        ...state,
        loading: true,
      }

    default:
      return state
  }
}

const initialState = {
  loading: true,
  cart: {
    discountCode: null,
    lineItems: [],
  },
}

const Store = props => {
  const [{ cart }, dispatch] = useReducer(reducer, initialState)
  const [cartId, setCartId] = useState()
  const [sellingPlans, setSellingPlans] = useState([])

  const [createCart, { data: createCartResponse, loading: createCartLoading }] =
    useMutation(CREATE_CART)
  const [addCartItems, { data: addCartItemsResponse, loading: addCartItemsLoading }] =
    useMutation(ADD_CART_ITEM)
  const [removeCartItems, { data: removeCartItemsResponse, loading: removeCartItemsLoading }] =
    useMutation(REMOVE_CART_ITEM)
  const [updateCartItems, { data: updateCartItemsResponse, loading: updateCartItemsLoading }] =
    useMutation(UPDATE_LINE_ITEM)
  const [
    updateCartDiscounts,
    { data: updateCartDiscountsResponse, loading: updateCartDiscountsLoading },
  ] = useMutation(UPDATE_CART_DISCOUNT)
  const [getCart, { data: getCartResponse, loading: getCartLoading }] = useLazyQuery(GET_CART)

  // Initial load of cart
  useEffect(() => {
    const cartId = localStorage.getItem('shopifyCartId')
    if (cartId) {
      getCart({
        variables: {
          id: cartId,
        },
      })
    }
    axios
      .get('/api/selling-plans')
      .then(response => {
        setSellingPlans(response.data)
      })
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (getCartResponse?.cart) {
      const mappedCart = mapCartData(getCartResponse.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      setCartId(getCartResponse.cart.id)
      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [getCartResponse])

  useEffect(() => {
    if (createCartResponse?.cartCreate?.cart) {
      const cartId = createCartResponse.cartCreate.cart.id
      localStorage.setItem('shopifyCartId', cartId)

      const mappedCart = mapCartData(createCartResponse.cartCreate.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      setCartId(cartId)
      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [createCartResponse])

  useEffect(() => {
    if (addCartItemsResponse?.cartLinesAdd?.cart) {
      const mappedCart = mapCartData(addCartItemsResponse.cartLinesAdd.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [addCartItemsResponse])

  useEffect(() => {
    if (removeCartItemsResponse?.cartLinesRemove?.cart) {
      const mappedCart = mapCartData(removeCartItemsResponse.cartLinesRemove.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [removeCartItemsResponse])

  useEffect(() => {
    if (updateCartItemsResponse?.cartLinesUpdate?.cart) {
      const mappedCart = mapCartData(updateCartItemsResponse.cartLinesUpdate.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [updateCartItemsResponse])

  useEffect(() => {
    if (updateCartDiscountsResponse?.cartDiscountCodesUpdate?.cart) {
      const mappedCart = mapCartData(updateCartDiscountsResponse.cartDiscountCodesUpdate.cart)
      localStorage.setItem('cart', JSON.stringify(mappedCart?.lineItems))
      localStorage.setItem('cartTotal', mappedCart?.totalPrice)

      dispatch({
        action: 'setCart',
        value: mappedCart,
      })
    }
  }, [updateCartDiscountsResponse])

  const [isOpen, setIsOpen] = useState(false)

  // Load SFMC fields from url and cookie
  useEffect(() => {
    const whiteListedParams = [
      'j', // Job ID
      'sfmc_sub', // Subscriber ID
      'l', // List ID
      'u', // ID of the landing page URL
      'jb', // Job batch ID
      'mid', // Your account number (member ID)
    ]

    const params = Object.fromEntries(
      whiteListedParams.map(name => [name, getParameterByName(name)])
    )
    if (params.j) {
      setCookie('sfmcParams', JSON.stringify(params))
    }
  }, [])

  const context = {
    ...cart,
    sellingPlans,
    loading:
      createCartLoading ||
      addCartItemsLoading ||
      removeCartItemsLoading ||
      updateCartItemsLoading ||
      getCartLoading ||
      updateCartDiscountsLoading,
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    totalCount: cart.lineItems.reduce((count, item) => count + item.quantity, 0),
    addItem: useCallback(
      item => {
        if (!cartId)
          createCart({
            variables: {
              input: {
                lines: [item],
              },
            },
          })
        else
          addCartItems({
            variables: {
              cartId,
              lines: [item],
            },
          })
      },
      [cartId]
    ),
    removeItem: useCallback(
      variantId => {
        removeCartItems({
          variables: {
            cartId,
            lineIds: [variantId],
          },
        })
      },
      [cartId]
    ),
    updateItem: useCallback(
      item =>
        updateCartItems({
          variables: {
            cartId,
            lines: [item],
          },
        }),
      [cartId]
    ),
    applyDiscount: useCallback(
      discountCode =>
        updateCartDiscounts({
          variables: {
            cartId,
            discountCodes: [discountCode],
          },
        }),
      [cartId]
    ),
    removeDiscount: useCallback(
      () =>
        updateCartDiscounts({
          variables: {
            cartId,
            discountCodes: [],
          },
        }),
      [cartId]
    ),
  }

  return <StoreContext.Provider value={context} {...props} />
}

export default Store
