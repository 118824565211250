import Cookies from 'js-cookie'

export const localAuthenticate = () => {
  const token = getAccessToken()
  if (token && token.length > 20) {
    // safe checking with length > 20
    return true
  }

  return false
}

export const setAccessToken = token => {
  Cookies.set('access_token', token)
}

export const getAccessToken = () => {
  const token = Cookies.get('access_token')
  return token
}

export const removeAccessToken = () => {
  return Cookies.remove('access_token')
}

export const setRefreshToken = token => Cookies.set('refresh_token', token)

export const getRefreshToken = () => {
  const token = Cookies.get('refresh_token')
  return token
}

export const removeRefreshToken = () => {
  return Cookies.remove('refresh_token')
}

export const setLocalAuthCredentials = (accessToken, refreshToken, loginID) => {
  setAccessToken(accessToken)
  setRefreshToken(refreshToken)
  Cookies.set('login_id', `${loginID}`)
}

export const clearLocalAuthCredentials = () => {
  removeAccessToken()
  removeRefreshToken()
  Cookies.remove('login_id')
}
