import { useState } from 'react'

export const useToggle = initial => {
  const [isToggled, setToggle] = useState(initial)
  const toggle = () => setToggle(prevState => !prevState)
  // 1. Array Method. Renamable outputs, best for multiple uses of hook in single component
  // return [isToggled, setToggle, toggle]

  // 2. Object Method. Named properties, no order in return necessary
  return { isToggled, setToggle, toggle }
}
