import Image from 'components/Image'
import Link from 'components/Link'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children

const ImageUpload = ({ text, interceptModal, url, linkClass, image }) => (
  <div css={{ margin: '2rem 0' }}>
    <ConditionalWrapper
      condition={url}
      wrapper={children => (
        <Link
          to={url}
          interceptModal={interceptModal}
          className={linkClass}
          css={{
            display: 'inline-flex',
            boxShadow: '0 0 0 0' + theme.colors.broccoli,
            transition: theme.fastTransition,
            '&:hover': {
              boxShadow: '0 0 0 6px' + theme.colors.broccoli,
            },
          }}
        >
          {children}
        </Link>
      )}
    >
      <Image {...image} alt={text} />
    </ConditionalWrapper>
  </div>
)

ImageUpload.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
  linkClass: PropTypes.string,
  interceptModal: PropTypes.object,
  image: PropTypes.object.isRequired,
}

export default ImageUpload

export const query = graphql`
  fragment ImageUpload on SanityImageUpload {
    text
    url
    linkClass
    interceptModal {
      ...InterceptModal
    }
    image {
      ...ImageWithPreview
    }
  }
`
