import BlockContent from 'components/BlockContent'
import Container from 'components/Container'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Input from 'components/forms/Input'
import { graphql } from 'gatsby'
import useIterableAction from 'hooks/useIterableAction'
import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import theme from 'styles/theme'

const InterceptModal = ({ body, isOpen, callToAction, onRequestClose, href, formAction }) => {
  const { email, setEmail, submitting, errors, handleSubmit } = useIterableAction({
    formName: 'interceptModal',
    iterableAction: formAction,
    redirectTo: href,
  })

  return (
    <Modal
      style={{
        overlay: {
          zIndex: theme.zIndex.modal,
        },
        content: {
          right: 0,
          top: 0,
          bottom: 0,
          left: 0,
          width: '100vw',
          padding: 0,
          border: 0,
          borderRadius: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      }}
      overlayClassName='ReactModal__HiddenOverlay'
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div css={{ textAlign: 'right', margin: '1.5rem' }}>
        <Icon.Close
          css={{
            height: '2rem',
            width: '2rem',
            color: theme.colors.b80,
            display: 'inline-block',
            cursor: 'pointer',
          }}
          onClick={onRequestClose}
        />
      </div>
      <Container css={{ textAlign: 'center', maxWidth: '30rem' }}>
        <BlockContent blocks={body} />
        <form onSubmit={handleSubmit}>
          <p>
            <Input
              type='email'
              placeholder='Enter your email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required={true}
            />
          </p>
          {!!errors.length && <p css={{ color: theme.colors.strawberry }}>{errors.join(', ')}</p>}
          <p>
            <Link
              theme='button'
              disabled={submitting}
              css={{
                width: '100%',
                backgroundColor: theme.colors.blueberry,
                fontSize: '1.5rem',
                textTransform: 'uppercase',
                fontWeight: '600',
                ':hover': {
                  backgroundColor: theme.colors.blueberryDark,
                },
              }}
              type='submit'
            >
              {callToAction}
            </Link>
          </p>
        </form>
      </Container>
      <div />
    </Modal>
  )
}

InterceptModal.propTypes = {
  body: PropTypes.array.isRequired,
  callToAction: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  href: PropTypes.string.isRequired,
  formAction: PropTypes.string.isRequired,
}

export default InterceptModal

export const query = graphql`
  fragment InterceptModal on SanityInterceptModal {
    body: _rawBody(resolveReferences: { maxDepth: 5 })
    callToAction
    formAction
  }
`
