import Icon from 'components/Icon'
import InvisibleButton from 'components/InvisibleButton'
import React from 'react'
import theme from 'styles/theme'

const SubscribeLearnMore = ({ onClose }) => {
  return (
    <div
      css={{
        position: 'absolute',
        left: '-3rem',
        bottom: '0',
        boxShadow: theme.boxShadow.deep,
        borderRadius: '0.25rem',
        padding: '1rem',
        backgroundColor: theme.colors.w100,
        width: '320px',
      }}
    >
      <div
        css={{
          ...theme.colors.h6,
          display: 'flex',
          alignItems: 'center',
          color: theme.colors.spinachDark,
          fontWeight: 'bold',
        }}
      >
        <Icon.Subscribe css={{ marginRight: '0.3rem' }} /> Subscribe &amp; Save 20%
      </div>
      <ul
        css={{
          ...theme.label,
          listStyle: 'none',
          paddingLeft: '0.3rem',
          textAlign: 'left',
          marginBottom: 0,
          li: {
            display: 'flex',
            marginBottom: '0.5rem',
            color: theme.colors.b100,
            '&:last-child': {
              marginBottom: 0,
            },
          },
          svg: {
            paddingRight: '0.3rem',
            width: '20px',
            height: '20px',
          },
          div: {
            flex: 1,
            fontSize: '0.875rem',
          },
        }}
      >
        <li>
          <Icon.Tick />
          <div>Save the most on our products</div>
        </li>
        <li>
          <Icon.Tick />
          <div>Swap, add to or cancel your subscription anytime</div>
        </li>
      </ul>
      <InvisibleButton
        className='RemoveAction__button'
        css={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
        }}
        onClick={onClose}
      >
        <Icon.Close
          className='RemoveAction__icon'
          width={20}
          height={20}
          css={{
            color: theme.colors.spinachDark,
          }}
        />
      </InvisibleButton>
    </div>
  )
}

export default SubscribeLearnMore
