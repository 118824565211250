import useCart from 'hooks/useCart'
import { useEffect, useState } from 'react'

const EmbeddedDiscountDetector = () => {
  const cart = useCart()
  const urlParams = new URLSearchParams(typeof window !== 'undefined' ? window.location.search : '')
  const newDiscountCode = urlParams.get('d')
  const [discountCode, setDiscountCode] = useState(newDiscountCode)

  useEffect(() => {
    if (
      newDiscountCode &&
      !cart.loading &&
      discountCode !== newDiscountCode &&
      newDiscountCode !== cart.discountCode
    ) {
      setDiscountCode(newDiscountCode)
    }
  }, [])

  useEffect(() => {
    if (cart.token && !cart.loading && discountCode && discountCode !== cart.discountCode) {
      cart.applyDiscount(discountCode)
      setDiscountCode(null)
    }
  }, [cart, discountCode])

  return null
}

export default EmbeddedDiscountDetector
