import { keyframes } from '@emotion/react'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const LoadingIndicator = ({ size = 64, thickness = 8, color = theme.colors.broccoliLight }) => (
  <div
    css={{
      position: 'relative',
      width: size,
      height: size,
      div: {
        position: 'absolute',
        width: size,
        height: size,
        border: `${thickness}px solid transparent`,
        borderLeftColor: color,
        borderRadius: size / 2,
        animation: `${spin} 1.5s cubic-bezier(0.62, 0.35, 0.43, 1) infinite`,
        ':nth-child(1)': { animationDelay: '-450ms' },
        ':nth-child(2)': { animationDelay: '-300ms' },
        ':nth-child(3)': { animationDelay: '-150ms' },
      },
    }}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

const spin = keyframes({
  '0%': { transform: 'rotate(30deg)' },
  '100%': { transform: 'rotate(390deg)' },
})

export default LoadingIndicator

LoadingIndicator.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
}
