import { navigate } from 'gatsby'
import { useCallback, useState } from 'react'

const useIterableAction = ({ formName, iterableAction, redirectTo }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState([])
  const handleSubmit = useCallback(
    async e => {
      e.preventDefault()

      if (!email.length) {
        setErrors(['Please enter an email address above'])
        return
      }

      setSubmitting(true)

      const formData = new window.FormData()
      formData.append('form-name', formName)
      formData.append('email', email)
      formData.append('firstName', name)
      const response = await window.fetch(iterableAction, {
        accept: 'application/json',
        method: 'POST',
        redirect: 'manual',
        body: formData,
      })

      if (response.ok || response.type === 'opaqueredirect') {
        setErrors([])
        navigate(redirectTo)
      } else {
        setErrors(['Sorry, there was an error when trying to save your email'])
      }
      setSubmitting(false)
    },
    [email, name, formName, iterableAction, redirectTo]
  )

  return {
    email,
    setEmail,
    name,
    setName,
    submitting,
    setSubmitting,
    errors,
    setErrors,
    handleSubmit,
  }
}

export default useIterableAction
