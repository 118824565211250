import Link from 'components/Link'
import React from 'react'
import theme from 'styles/theme'

const EmptyCart = props => (
  <div
    css={{
      padding: '5rem 0',
      textAlign: 'center',
    }}
  >
    <h2 css={{ ...theme.h2, color: theme.colors.spinach }}>Your cart is empty.</h2>
    <p
      css={{
        maxWidth: '22em',
        margin: '2rem auto',
      }}
    >
      You deserve the best protein in the business. Pick your protein, just how you like it!
    </p>
    <Link
      to='/collections/all/'
      onClick={e => cart.close()}
      theme='button'
      css={{
        backgroundColor: theme.colors.tumeric,
        color: theme.colors.b90,
        padding: '1.5rem',
        '&:hover': {
          backgroundColor: theme.colors.tumericDark,
        },
      }}
    >
      Shop Now →
    </Link>
  </div>
)

export default EmptyCart
