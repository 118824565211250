import { getFormattedPrice } from 'utils/pricing'

export const mapCartData = ({ estimatedCost, discountCodes, lines, ...rest }) => {
  const subtotalPrice = lines?.edges
    ? lines?.edges
        ?.reduce(
          (t, { node: line }) => t + parseFloat(line?.estimatedCost?.subtotalAmount?.amount || 0),
          0
        )
        .toFixed(2)
    : getFormattedPrice(estimatedCost?.subtotalAmount?.amount)

  const totalPrice = getFormattedPrice(estimatedCost?.totalAmount?.amount)
  const appliedDiscount = getFormattedPrice(totalPrice - subtotalPrice)

  return {
    totalPrice,
    subtotalPrice,

    totalTaxAmount: getFormattedPrice(estimatedCost?.totalTaxAmount?.amount),
    discountCode:
      discountCodes.length > 0 && discountCodes[0].applicable ? discountCodes[0].code : null,

    // New way of calculating appliedDiscount
    // = total - subtotal
    appliedDiscount:
      discountCodes.length > 0
        ? {
            value: appliedDiscount,
            ...discountCodes[0],
          }
        : null,

    // DISCOUTNT CAVEAT
    // I found inconsistencies between various PromoCodes and how they handle discounts and subtotal.
    // For example DOUBLE2024X5L6V6 reduces the product subtotal directly to total amount, thus making the discount to appear as 0,
    // while other code - ABANDONFNBR4CMV, OWYNBFCM, HRID7ADV, they do not modify the subtotal, thus making discount calculation work.
    // Thus deprecated this way of calculating appliedDiscount (below) and replaced it with the new one (above).

    // Old way of calculating appliedDiscount
    //  appliedDiscount: discountCodes.length > 0
    //     ? {
    //         value: lines?.edges
    //           ? lines?.edges
    //               ?.reduce(
    //                 (t, { node: line }) =>
    //                   t + parseFloat(line?.discountAllocations?.[0]?.discountedAmount?.amount || 0),
    //                 0
    //               )
    //               .toFixed(2)
    //           : getFormattedPrice(
    //               estimatedCost?.totalAmount?.amount - estimatedCost?.subtotalAmount?.amount
    //             ),
    //         ...discountCodes[0],
    //       }
    //     : null,

    lineItems: lines?.edges?.map(({ node }) => {
      const {
        id: lineId,
        merchandise,
        estimatedCost,
        sellingPlanAllocation,
        ...restLineProps
      } = node

      return {
        lineId,
        variantId: merchandise?.id,
        image: merchandise?.image?.src,
        title: merchandise?.product?.title,
        productId: merchandise?.product?.id,
        variantSku: merchandise?.sku,
        variantTitle: merchandise?.title,
        linePrice: estimatedCost?.subtotalAmount?.amount,
        price: sellingPlanAllocation
          ? sellingPlanAllocation.priceAdjustments[0]?.price?.amount
          : merchandise?.priceV2?.amount,
        sellingPlanAllocation,
        ...restLineProps,
      }
    }),
    ...rest,
  }
}
