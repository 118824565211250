import ContentContainer from 'components/ContentContainer'
import Image from 'components/Image'
import Link from 'components/Link'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Bifold = ({ _key, heading, copy, cta, image }) => (
  <section
    id={`block-${_key}`}
    className='Bifold'
    css={{
      backgroundColor: theme.colors.w100,
      color: theme.colors.spinachDark,
      padding: '2rem 0',
      [theme.medium]: {
        padding: '2.5rem 0',
      },
      '& + &:nth-of-type(2n)': {
        '.image-panel': {
          order: 2,
        },
      },
    }}
  >
    <ContentContainer
      styles={{
        margin: '0 auto',
        [theme.medium]: {
          display: 'flex',
          '> div': {
            flex: '50%',
          },
        },
      }}
    >
      {image && <ImagePanel image={image} alt={heading} className='image-panel' />}
      <TextPanel heading={heading} copy={copy} cta={cta} />
    </ContentContainer>
  </section>
)

export default Bifold

Bifold.propTypes = {
  _key: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkClass: PropTypes.string,
    interceptModal: PropTypes.object,
  }),
  image: PropTypes.shape({
    asset: PropTypes.shape({
      metadata: PropTypes.shape({
        preview: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

const TextPanel = ({ heading, copy, cta }) => (
  <div
    css={{
      padding: '0 1rem 2rem',
      [theme.medium]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '2rem 4.5rem',
      },
    }}
  >
    <h3
      css={{
        ...theme.h3,
        fontWeight: theme.fontWeight.bold,
        color: 'inherit',
      }}
    >
      {heading}
    </h3>

    {copy && (
      <p
        css={{
          ...theme.h5,
          fontWeight: theme.fontWeight.normal,
          lineHeight: '1.6',
          marginTop: '1rem',
          marginBottom: 0,
          maxWidth: '32rem',
        }}
      >
        {copy}
      </p>
    )}

    {cta && (
      <Link
        theme='button'
        to={cta.url}
        interceptModal={cta.interceptModal}
        className={cta.linkClass}
        css={{ marginTop: 16, display: 'inline-block' }}
      >
        {cta.text}
      </Link>
    )}
  </div>
)

TextPanel.propTypes = {
  heading: PropTypes.string.isRequired,
  copy: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkClass: PropTypes.string,
    interceptModal: PropTypes.object,
  }),
}

const ImagePanel = ({ image, alt, className }) => (
  <div css={{ position: 'relative' }} className={className}>
    {image.asset && (
      <Image
        {...image}
        width={600}
        alt={alt}
        css={{
          display: 'block',
          width: '100%',
        }}
      />
    )}
  </div>
)

ImagePanel.propTypes = {
  image: PropTypes.shape({
    asset: PropTypes.shape({
      metadata: PropTypes.shape({
        preview: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export const query = graphql`
  fragment Bifold on SanityBifold {
    heading
    copy
    cta {
      ...Link
    }
    image {
      ...ImageWithPreview
    }
  }
`
