import Image from 'components/Image'
import Link from 'components/Link'
import PortableText from 'components/PortableText'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const FeaturedProduct = ({ name, description, slug, image }) => {
  return (
    <div
      css={{
        margin: '2rem 0!important',
        maxWidth: 'unset!important',
        padding: '2rem 1rem',
        backgroundColor: theme.colors.white,
        [theme.mediumDown]: {
          margin: '2rem -1rem!important',
        },
      }}
    >
      <div
        css={{
          maxWidth: '48rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: 'auto',
          [theme.mediumDown]: {
            flexDirection: 'column',
          },
        }}
      >
        <div
          css={{
            width: '300px',
            [theme.mediumDown]: {
              width: '220px',
            },
          }}
        >
          <Image asset={{ _id: image.image.asset._ref }} width={300} alt={image.alt} />
        </div>
        <div
          css={{
            width: 'calc(100% - 320px)',
            p: {
              margin: '0 0 1rem',
            },
            [theme.mediumDown]: {
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: {
                margin: '0 0 1rem',
                textAlign: 'center',
              },
            },
          }}
        >
          <span
            css={{
              padding: '9.5px 12px',
              ...theme.smallcaps,
              fontWeight: 'bold',
              backgroundColor: '#E5F1EC',
              borderRadius: '4px',
              marginBottom: '1rem',
              display: 'block',
              width: 'fit-content',
            }}
          >
            FEATURED PRODUCT
          </span>
          <h3
            css={{
              fontSize: '30px',
              lineHeight: '40px',
              fontWeight: 'bold!important',
              margin: '0!important',
            }}
          >
            {name}
          </h3>
          <PortableText>{description}</PortableText>
          <Link
            to={slug.current}
            css={{
              '--buttonColor': theme.colors.spinachDark,
              '--buttonBackground': 'transparent',
              ...theme.paragraph,
              padding: '6px 16px',
              border: '1px solid',
              borderRadius: '999px',
              transition: theme.globalTransition,
              '&:hover': {
                backgroundColor: theme.colors.spinachDark,
                color: theme.colors.white,
              },
            }}
          >
            Shop Now
          </Link>
        </div>
      </div>
    </div>
  )
}

FeaturedProduct.propTypes = {
  name: PropTypes.string,
  description: PropTypes.array.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.shape({
      asset: PropTypes.shape({
        _ref: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }),
  slug: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }).isRequired,
}

export default FeaturedProduct
