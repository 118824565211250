import { gql } from '@apollo/client'

export const GET_CUSTOMER = gql`
  query getCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
      defaultAddress {
        id
        address1
        address2
        company
        city
        country
        countryCodeV2
        province
        provinceCode
        zip
      }
    }
  }
`

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressCreate($address: MailingAddressInput!, $customerAccessToken: String!) {
    customerAddressCreate(address: $address, customerAccessToken: $customerAccessToken) {
      customerAddress {
        id
        address1
        address2
        city
        country
        company
        countryCodeV2
        province
        provinceCode
        zip
      }
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressUpdate(
    $address: MailingAddressInput!
    $customerAccessToken: String!
    $id: ID!
  ) {
    customerAddressUpdate(address: $address, customerAccessToken: $customerAccessToken, id: $id) {
      customerAddress {
        id
        address1
        address2
        city
        country
        company
        countryCodeV2
        province
        provinceCode
        zip
      }
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
    customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`

export const DEFAULT_ADDRESS_UPDATE = gql`
  mutation customerDefaultAddressUpdate($addressId: ID!, $customerAccessToken: String!) {
    customerDefaultAddressUpdate(addressId: $addressId, customerAccessToken: $customerAccessToken) {
      customerUserErrors {
        code
        message
        field
      }
    }
  }
`
