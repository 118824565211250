import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const REMOVE_CART_ITEM = gql`
  ${CORE_FRAGMENTS}
  mutation REMOVE_CART_ITEM($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...CartResponse
      }
      userErrors {
        field
        message
      }
    }
  }
`
