import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const GET_CART = gql`
  ${CORE_FRAGMENTS}
  query GET_CART($id: ID!) {
    cart(id: $id) {
      ...CartResponse
    }
  }
`
