/* eslint-disable react/prop-types */
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { cache, hydrate } from '@emotion/css'
import { CacheProvider, Global } from '@emotion/react'
import CustomerInfoFetcher from 'components/CustomerInfoFetcher'
import EmbeddedDiscountDetector from 'components/EmbeddedDiscountDetector'
import Layout from 'components/Layout'
import Store from 'components/Store'
import fetch from 'isomorphic-fetch'
import { fireElevarAnalyticsEventGeneric } from 'lib/analytics'
import React from 'react'
import { Provider } from 'react-redux'
import { globalStyles } from 'styles/theme'

import { store } from './src/store'

const client = new ApolloClient({
  uri: 'https://owyn.myshopify.com/api/2022-04/graphql.json',
  cache: new InMemoryCache(),
  fetch,
  headers: {
    'Content-Type': 'application/json',
    'x-shopify-storefront-access-token': process.env.SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  },
})

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <CacheProvider value={cache}>
          <CustomerInfoFetcher>
            <Store>{element}</Store>
          </CustomerInfoFetcher>
        </CacheProvider>
      </ApolloProvider>
    </Provider>
  )
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <div id='react_modal' />
      {/* <!-- Google Tag Manager (noscript) --> */}
      {/* <noscript>
        <iframe
          src='https://www.googletagmanager.com/ns.html?id=GTM-TX6DD9B'
          height='0'
          width='0'
          style='display:none;visibility:hidden'></iframe>
      </noscript> */}
      {/* <!-- End Google Tag Manager (noscript) --> */}

      <Global styles={globalStyles} />
      <EmbeddedDiscountDetector />
      <Layout>{element}</Layout>
    </>
  )
}

export const onClientEntry = () => {
  // This JSON script gets written in gatsby-ssr
  const idsTag = document.getElementById('hydrated-emotion-ids')

  if (idsTag) {
    const ids = JSON.parse(idsTag.innerHTML)
    hydrate(ids)
  }
}

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname)
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null)

  const cartLineItemsStr = localStorage.getItem('cart')
  const cartLineItems = cartLineItemsStr ? JSON.parse(cartLineItemsStr) : []

  // Map the cart.lineItems to the elevarProductArr
  const mappedProductsArr = cartLineItems?.map((item, index) => {
    const variant_id = item?.variantId?.split('/')?.[4]

    return {
      id: item?.variantSku || '', // SKU
      name: item?.title || '', // Product title
      brand: 'OWYN',
      category: '',
      variant: item?.variantTitle || '',
      price: item?.price.toString() || '',
      quantity: item?.quantity.toString() || '',
      list: '', // The list the product was discovered from
      product_id: variant_id || '', // The product_id is the variant_id
      variant_id: variant_id || '',
      compare_at_price: '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
      image: item?.image || '', // If available, otherwise use an empty string
      inventory: '',
      // position: index + 1,
    }
  })

  var elevarPayloadCore = {
    cart_contents: {
      products: mappedProductsArr || [], // See the products array below
    },
  }

  fireElevarAnalyticsEventGeneric('dl_user_data', elevarPayloadCore)

  // Any navigation changes without a reload will ideally notify Elevar via a utility.
  // This utility will update the contextual information that Elevar uses to enrich Data Layer events.
  // The utility can be used like so:
  // window?.ElevarGtmSuiteAAT.utils.pushContextToDataLayer()

  // https://docs.getelevar.com/docs/how-to-upgrade-data-layer-customizations-to-be-compatible-with-new-shopify-source#update-your-route-change-code
  window.ElevarInvalidateContext?.()

  // If `window.ElevarGtmSuiteAAT` isn’t available because of load order then this call can be
  // skipped as it will be called automatically when the script has loaded.
}
