import PropTypes from 'prop-types'
import React from 'react'

const Label = ({ name, props, children, styles }) => (
  <label
    htmlFor={name}
    {...props}
    css={{
      fontWeight: 'bold',
      display: 'inline-block',
      marginBottom: '0.5rem',
      ...styles,
    }}
  >
    {children}
  </label>
)

Label.propTypes = {
  name: PropTypes.string,
  props: PropTypes.object,
  children: PropTypes.node,
  styles: PropTypes.object,
}

export default Label
