module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OWYN - Only What You Need","short_name":"OWYN","start_url":"/","background_color":"#FBF7F2","theme_color":"#16806C","display":"minimal-ui","icon":"src/images/OWYN-Icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aba748984220c711a2bbd5108eced8e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TX6DD9B","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"gtmAuth":"KMq5U3sZYgJ_-TEzhluagQ","dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","includeInDevelopment":false,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
