import { ReactComponent as ArrowBack } from 'images/icons/ArrowBack.svg'
import { ReactComponent as ArrowForward } from 'images/icons/ArrowForward.svg'
import { ReactComponent as Bottle } from 'images/icons/Bottle.svg'
import { ReactComponent as CartFill } from 'images/icons/CartFill.svg'
import { ReactComponent as CartOutline } from 'images/icons/CartOutline.svg'
import { ReactComponent as Close } from 'images/icons/Close.svg'
import { ReactComponent as CloseCircle } from 'images/icons/CloseCircle.svg'
import { ReactComponent as DropdownArrow } from 'images/icons/DropdownArrow.svg'
import { ReactComponent as Facebook } from 'images/icons/Facebook.svg'
import { ReactComponent as FilterList } from 'images/icons/FilterList.svg'
import { ReactComponent as Instagram } from 'images/icons/Instagram.svg'
import { ReactComponent as Label } from 'images/icons/Label.svg'
import { ReactComponent as Menu } from 'images/icons/Menu.svg'
import { ReactComponent as MinusCircle } from 'images/icons/MinusCircle.svg'
import { ReactComponent as PersonOutline } from 'images/icons/PersonOutline.svg'
import { ReactComponent as PlusCircle } from 'images/icons/PlusCircle.svg'
import { ReactComponent as RightArrow } from 'images/icons/RightArrow.svg'
import { ReactComponent as SaleCallout } from 'images/icons/SaleCallout.svg'
import { ReactComponent as SkipTwoArrow } from 'images/icons/SkipTwoArrow.svg'
import { ReactComponent as StarFill } from 'images/icons/StarFill.svg'
import { ReactComponent as Subscribe } from 'images/icons/Subscribe.svg'
import { ReactComponent as TetraCarton } from 'images/icons/TetraCarton.svg'
import { ReactComponent as TetraCartonSolid } from 'images/icons/TetraCartonSolid.svg'
import { ReactComponent as Tick } from 'images/icons/Tick.svg'
import { ReactComponent as Truck } from 'images/icons/Truck.svg'
import { ReactComponent as UpdateSubscription } from 'images/icons/UpdateSubscription.svg'
import { ReactComponent as Verified } from 'images/icons/Verified.svg'

const Icon = {
  ArrowBack,
  ArrowForward,
  Bottle,
  CartFill,
  CartOutline,
  Close,
  CloseCircle,
  DropdownArrow,
  Facebook,
  FilterList,
  Instagram,
  Menu,
  MinusCircle,
  PersonOutline,
  PlusCircle,
  RightArrow,
  SaleCallout,
  StarFill,
  TetraCarton,
  TetraCartonSolid,
  Truck,
  Verified,
  Tick,
  Subscribe,
  SkipTwoArrow,
  UpdateSubscription,
  Label,
}

export default Icon
