import Icon from 'components/Icon'
import InvisibleButton from 'components/InvisibleButton'
import twoPassRender from 'lib/twoPassRender'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const QuantitySelector = twoPassRender(({ value, dispatch, className }) => (
  <div
    css={{
      color: theme.colors.spinachDark,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      userSelect: 'none', // prevent accidental highlighting
    }}
    className={className}
  >
    <InvisibleButton
      onClick={() => dispatch({ change: { quantity: Math.max(1, value - 1) } })}
      css={{
        padding: '1rem',
        height: '100%',
      }}
    >
      <span className='sr-only'>Decrease Quantity by 1</span>
      <Icon.MinusCircle width={24} />
    </InvisibleButton>

    <div css={{ padding: '0 0.5em' }}>{value}</div>

    <InvisibleButton
      onClick={() => dispatch({ change: { quantity: Math.min(100, value + 1) } })}
      css={{
        padding: '1rem',
        height: '100%',
      }}
    >
      <span className='sr-only'>Increase Quantity by 1</span>
      <Icon.PlusCircle width={24} />
    </InvisibleButton>
  </div>
))

export default QuantitySelector

QuantitySelector.propTypes = {
  value: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
}
