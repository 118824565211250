import { useCallback, useEffect } from 'react'

const useOnClickOutside = (ref, handler, active = true) => {
  const listener = useCallback(
    e => {
      if (Array.isArray(ref)) {
        const clickedRef = ref
          .filter(singleRef => singleRef.current)
          .find(singleRef => singleRef.current.contains(e.target))
        if (!clickedRef) handler(e)
      } else if (ref.current && !ref.current.contains(e.target)) handler(e)
    },
    [ref, handler]
  )

  useEffect(() => {
    if (active) {
      document.addEventListener('click', listener)
    } else {
      document.removeEventListener('click', listener)
    }

    return () => {
      document.removeEventListener('click', listener)
    }
  }, [listener, active])
}

export default useOnClickOutside
