import InterceptModal from 'components/InterceptModal'
import LinkWithoutIntercept from 'components/LinkWithoutIntercept'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const Link = ({ interceptModal, to, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  if (interceptModal && to) {
    return (
      <>
        <LinkWithoutIntercept to='#' {...rest} onClick={() => setIsOpen(true)} />
        <InterceptModal
          body={interceptModal.body}
          callToAction={interceptModal.callToAction}
          href={to}
          formAction={interceptModal.formAction}
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
        />
      </>
    )
  } else {
    return <LinkWithoutIntercept to={to} {...rest} />
  }
}

export default Link

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  theme: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  interceptModal: PropTypes.shape({
    body: PropTypes.array.isRequired,
    callToAction: PropTypes.string.isRequired,
    formAction: PropTypes.string.isRequired,
  }),
}

export const query = graphql`
  fragment Link on SanityLink {
    text
    url
    linkClass
    interceptModal {
      ...InterceptModal
    }
  }
`
