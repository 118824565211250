import BlockContent from '@sanity/block-content-to-react'
import PropTypes from 'prop-types'
import React from 'react'
import slugify from 'slugify'

const PortableText = ({ children }) => (
  <BlockContent
    projectId={process.env.SANITY_PROJECT_ID}
    dataset={process.env.SANITY_DATASET}
    serializers={serializers}
    blocks={children || []}
  />
)

const BlockRenderer = props => {
  const { style = 'normal' } = props.node

  if (/^h\d/.test(style) && props.children.length > 0 && typeof props.children[0] === 'string') {
    return React.createElement(
      style,
      { id: slugify(props.children[0].toLowerCase()) },
      props.children
    )
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}
BlockRenderer.propTypes = {
  node: PropTypes.object,
  options: PropTypes.object,
  children: PropTypes.array,
}

const underlineMark = props => <span className='underline'>{props.children}</span>
underlineMark.propTypes = { children: PropTypes.array }

const serializers = {
  marks: {
    underline: underlineMark,
  },
  types: {
    block: BlockRenderer,
  },
}

export default PortableText

PortableText.propTypes = {
  children: PropTypes.array,
}
