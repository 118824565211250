import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Input = ({ styles, size, ...props }) => (
  <input
    css={{
      fontSize: 16,
      border: `1px solid ${theme.colors.b20}`,
      outline: 'none',
      display: 'block',
      width: '100%',
      height: size === 'sm' ? 48 : 64,
      padding: '0 1rem',
      transition: theme.globalTransition,
      ':hover': {
        border: `1px solid ${theme.colors.b40}`,
      },
      ':focus': {
        border: `1px solid ${theme.colors.broccoli}`,
        outline: 'none',
      },
      ...styles,
    }}
    {...props}
  />
)

Input.propTypes = {
  styles: PropTypes.object,
}

export default Input
