import React from 'react'
import theme from 'styles/theme'

export const CartHeading = () => (
  <div
    className='CartHeading'
    css={{
      [theme.smallOnly]: {
        display: 'none',
      },
      display: 'grid',
      gridTemplateColumns: '6.25rem 2fr 1fr 2fr',
      gap: '1rem',
      alignItems: 'end',
      paddingBottom: '1rem',
      marginBottom: '1.5rem',
      borderBottom: '1px solid currentColor',
      color: theme.colors.spinach,
      '.label': {
        ...theme.smallcaps,
        color: theme.colors.spinachDark,
      },
      '.label-group.quantity': {
        display: 'flex',
        justifyContent: 'space-between',
      },
    }}
  >
    <div
      css={{
        ...theme.h2,
        lineHeight: '1',
        position: 'relative',
        bottom: '-.4rem',
      }}
    >
      Cart
    </div>
    <div className='label'>Product</div>
    <div className='label'>Price</div>
    <div className='label-group quantity'>
      <div className='label'>Quantity</div>
      <div className='label' css={{ textAlign: 'right' }}>
        Total
      </div>
    </div>
  </div>
)
