const fonts = {
  normal: {
    '@font-face': {
      fontFamily: 'Athletics',
      src: `url("/fonts/Athletics-Regular.woff2") format("woff2")`,
      fontWeight: '400',
      fontStyle: 'normal',
      fontDisplay: 'fallback',
      fontDisplay: 'swap',
    },
  },
}

export default fonts
