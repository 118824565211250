import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Container = ({
  id,
  max = theme.maxContainerWidth,
  children,
  wrapperStyles = {},
  className,
}) => (
  <section css={wrapperStyles} id={id}>
    <div
      css={{
        width: '92%',
        maxWidth: max,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      className={className}
    >
      {children}
    </div>
  </section>
)

export default Container

Container.propTypes = {
  id: PropTypes.string,
  max: PropTypes.string,
  children: PropTypes.node.isRequired,
  wrapperStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
}
