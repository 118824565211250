import Footer from 'components/Footer'
import Header from 'components/Header'
import NotificationBar from 'components/NotificationBar'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const LayoutContext = React.createContext()

const Layout = ({ children }) => (
  <LayoutContextProvider>
    <NotificationBar />
    <Header />
    {children}
    <Footer />
  </LayoutContextProvider>
)

const LayoutContextProvider = props => {
  const [showHeader, setShowHeader] = useState(true)
  const [showFooter, setShowFooter] = useState(true)
  const [showFDADisclaimer, setShowFDADisclaimer] = useState(false)
  const [showSecondDisclaimer, setShowSecondDisclaimer] = useState(false)
  const context = {
    showHeader,
    setShowHeader,
    showFooter,
    setShowFooter,
    showFDADisclaimer,
    setShowFDADisclaimer,
    showSecondDisclaimer,
    setShowSecondDisclaimer,
  }
  return <LayoutContext.Provider value={context} {...props} />
}

export default Layout

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
