const colors = {
  // Text Colors
  b100: '#000',
  b80: 'hsla(0, 0%, 0%, 0.8)',
  b90: 'hsla(0, 0%, 0%, 0.9)',
  b60: 'hsla(0, 0%, 0%, 0.6)',
  b50: 'hsla(0, 0%, 0%, 0.5)',
  b40: 'hsla(0, 0%, 0%, 0.4)',
  b30: 'hsla(0, 0%, 0%, 0.3)',
  b20: 'hsla(0, 0%, 0%, 0.2)',
  b10: 'hsla(0, 0%, 0%, 0.1)',
  w100: '#FFF',
  w90: 'hsla(0, 0%, 100%, 0.9)',
  kale: 'hsla(187, 50.7%, 13.5%, 0.8)',

  // ---
  // Brand Colors

  // Blueberry
  blueberryLight: '#DAE6EF',
  blueberryLightSecond: '#D9E5F1',
  blueberry: '#2b7da9',
  blueberryDark: '#396D95',

  brightBlue: '#0a1f8f',
  brightLightBlue: '#52a7a8',
  brightOrange: '#ff9e1a',
  orangeLight: '#ffc668',

  // Broccoli
  broccoliLight: '#D1EBE4',
  broccoli: '#8BCEBB',
  broccoliDark: '#70B09E',
  broccoliSecond: '#B1D0CA',

  // Creamless
  creamlessLight: '#FDF9F6',
  creamless: '#FBF4EF',
  creamlessDark: '#FBEDE4',

  // Eggplant
  eggplantLight: '#F0EEF3',
  eggplant: '#70588A',
  eggplantDark: '#5E4975',

  // Peach
  peachLight: '#FDF5F2',
  peach: '#F4C0AA',
  peachDark: '#DDAF9B',

  // Spinach
  spinachLight: '#B9D8D2',
  spinach: '#16806C',
  spinachDark: '#144F46',
  spinachBlack: '#112326',

  // Strawberry
  strawberryLight: '#FBEBE5',
  strawberry: '#FF4847',
  strawberryDark: '#DB3E3D',

  // Tumeric
  tumericLight: '#FFF6E0',
  tumeric: '#FFD567',
  tumericDark: '#EAC45E',

  // Neutrals
  black100: '#000',
  gray: '#D8D8D8',
  silver: '#E8E8E8',
  white: '#FFFFFF',

  // Miscellaneous
  miscDarkChocolate: '#7C281D',
  miscGoldenMylk: '#E0A728',
  miscBackground: '#FBF7F2',
  cabbage: '#0A1D1F',

  // Pro Elite
  eliteBrown: '#363432',
  eliteLightBrown: '#a67f6a',
  eliteGray: '#6b7174',

  // Koromiko
  koromiko: '#FFC167',
}

export default colors
