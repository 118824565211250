import Icon from 'components/Icon'
import Link from 'components/Link'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const DISCOUNT_ERROR_MAPPING = {
  discount_does_not_apply_to_cart: 'This discount code is not applicable for these items',
  subtotal_less_than_prerequisite_subtotal_min:
    'Subtotal is below the minimum for this discount to apply',
}

const mapErrorCode = rawError => {
  return DISCOUNT_ERROR_MAPPING[rawError] || rawError
}

export default class DiscountInput extends React.PureComponent {
  static propTypes = {
    initialValue: PropTypes.string.isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    applyDiscount: PropTypes.func.isRequired,
    removeDiscount: PropTypes.func.isRequired,
    cartEmpty: PropTypes.bool.isRequired,
  }

  state = {
    showInput: false,
    localValue: this.props.initialValue || '',
    submitting: false,
  }

  inputRef = React.createRef()

  handleChange = e => {
    this.setState({
      localValue: e.target.value.toUpperCase().replace(/[_ -]+/g, '-'),
    })
  }

  apply = async e => {
    const { applyDiscount, removeDiscount } = this.props
    const { localValue } = this.state

    e.preventDefault()
    this.setState({ submitting: true })

    if (localValue.length) {
      await applyDiscount(localValue)
    } else {
      await removeDiscount()
    }

    this.setState({ submitting: false })
  }

  showInput = () => {
    this.setState({ showInput: true }, () => {
      this.inputRef.current && this.inputRef.current.focus()
    })
  }

  hideInput = () => {
    this.setState({ showInput: false, localValue: '' })
  }

  render() {
    const { error: rawError, initialValue, cartEmpty } = this.props
    const { localValue, submitting, showInput } = this.state

    const error = mapErrorCode(rawError)
    const showError = error && initialValue === localValue && !cartEmpty

    return showInput || error ? (
      <form onSubmit={this.apply} css={{ position: 'relative' }}>
        <input
          ref={this.inputRef}
          placeholder='Discount Code'
          value={localValue}
          onChange={this.handleChange}
          css={{
            ...theme.label,
            display: 'block',
            width: showError ? '100%' : 'calc(100% - 2px)',
            padding: '1.25em 5em 1.25em 1em',
            margin: showError ? 0 : 1,
            borderRadius: 3,
            outline: 0,
            border: showError
              ? `2px solid ${theme.colors.strawberry}`
              : `1px solid ${theme.colors.gray}`,
            ':focus': {
              margin: 0,
              width: '100%',
              border: `2px solid ${showError ? theme.colors.strawberry : theme.colors.broccoli}`,
            },
            '::placeholder': {
              color: theme.colors.spinachLight,
            },
          }}
        />
        <Link
          theme='button'
          css={[
            {
              position: 'absolute',
              top: '0.375em',
              right: '0.375em',
              '--buttonBackground': theme.colors.spinach,
              '--buttonColor': theme.colors.white,
              '--buttonShadow': 'unset',
              ':hover': {
                transform: 'none',
              },
            },
            (submitting || showError) && {
              opacity: 0.5,
              '--buttonBackground': theme.colors.gray,
            },
          ]}
          disabled={submitting || showError}
        >
          Apply
        </Link>
        <Icon.CloseCircle
          onClick={this.props.initialValue ? this.props.removeDiscount : this.hideInput}
          height={23}
          width={23}
          css={{
            position: 'absolute',
            top: '1.15625em',
            right: '-21px',
            opacity: '.5',
            cursor: 'pointer',
            transition: 'opacity 250ms',
            ':hover': {
              opacity: '.8',
            },
          }}
        />

        {showError && (
          <div
            css={{
              fontSize: 14,
              lineHeight: '1.2',
              color: theme.colors.strawberry,
              marginTop: '0.75rem',
            }}
          >
            {error}
          </div>
        )}
      </form>
    ) : (
      <div
        onClick={this.showInput}
        css={{ cursor: 'pointer', ':hover': { color: theme.colors.spinach } }}
      >
        + Add a discount code
      </div>
    )
  }
}
