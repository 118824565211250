import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const UPDATE_CART_DISCOUNT = gql`
  ${CORE_FRAGMENTS}
  mutation UPDATE_CART_DISCOUNT($cartId: ID!, $discountCodes: [String!]) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...CartResponse
      }
      userErrors {
        field
        message
      }
    }
  }
`
