import DesktopNav from 'components/DesktopNav'
import Link from 'components/Link'
import MobileNav from 'components/MobileNav'
import { CartButton } from 'components/cart-button'
import { graphql, useStaticQuery } from 'gatsby'
import useLayout from 'hooks/useLayout'
import { ReactComponent as Logo } from 'images/owyn-logo.svg'
import React from 'react'
import theme from 'styles/theme'

const Header = () => {
  const layout = useLayout()
  const { header } = useStaticQuery(graphql`
    {
      header: sanityHeader {
        dropdownTitleLink {
          text
          url
          linkClass
        }
        dropdownLinks {
          ...ProductCard
        }
        userAccountLink
        mainLinks {
          link {
            text
            url
            linkClass
          }
          navPosition
          navigationGroup {
            links {
              text
              url
              linkClass
            }
          }
          ctaButtonText
        }
      }
    }
  `)

  if (!layout.showHeader) {
    return null
  }

  return (
    <header
      css={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.colors.w100,
        position: 'sticky',
        top: 0,
        zIndex: theme.zIndex.navigation,
        [theme.headerBreakpoint.small]: {
          flexDirection: 'column',
        },
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'stretch',
          justifyContent: 'space-between',
          maxWidth: 1280,
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
          borderBottom: `1px solid ${theme.colors.spinach}`,
          position: 'relative',
          [theme.headerBreakpoint.large]: {
            width: '98%',
            marginLeft: '1.5rem',
            marginRight: '1.5rem',
          },
        }}
      >
        <MobileNav header={header} />

        <LogoLink />

        <DesktopNav header={header} />

        <CartButton />
      </div>
    </header>
  )
}

const LogoLink = () => (
  <Link to='/' css={{ display: 'flex', alignItems: 'center' }}>
    <Logo title='OWYN | Only What You Need' css={{ color: theme.colors.cabbage, width: 94 }} />
    <span className='sr-only'>OWYN | Only What You Need</span>
  </Link>
)

export default Header
