import normalize from 'emotion-normalize'
import expandQueries from 'lib/expandQueries'

import colors from './colors'
import fonts from './fonts'

const breakpoints = {
  xlarge: '@media (min-width: 1450px)',
  large: '@media (min-width: 900px)',
  mediumDown: '@media (max-width: 899px)',
  mediumOnly: '@media (min-width: 600px) and (max-width: 900px)',
  medium: '@media (min-width: 600px)',
  smallOnly: '@media (max-width: 599px)',
  tiny: '@media (min-width: 370px)',
}

const headerBreakpoint = {
  small: '@media (max-width: 799px)',
  large: '@media (min-width: 800px)',
}

const zIndex = {
  content: '10',
  navigation: '20',
  modal: '30',
}

const fontWeight = {
  normal: '400',
  medium: '500',
  bold: '600',
}

const boxShadow = {
  slight: '0 1px 2px 0 rgba(0, 0, 0, .12)',
  deep: '0 25px 25px rgba(0, 0, 0, .2)',
}

const fontFamily = `Athletics, system-ui, sans-serif`

const defaultExpansionBreaks = ['root', breakpoints.medium, breakpoints.large]

export default {
  colors,
  ...breakpoints,
  headerBreakpoint,
  zIndex,
  fontWeight,
  boxShadow,

  expandQueries: (styles, breaks) => expandQueries(styles, breaks || defaultExpansionBreaks),

  maxContainerPercent: '92%',
  maxContainerWidth: '80rem',
  containerPadding: expandQueries(
    {
      paddingLeft: ['2rem', '2.5rem'],
      paddingRight: ['2rem', '2.5rem'],
    },
    defaultExpansionBreaks
  ),
  verticalPadding: expandQueries(
    {
      paddingTop: ['4.5rem', '6rem', '7.5rem'],
      paddingBottom: ['4.5rem', '6rem', '7.5rem'],
    },
    defaultExpansionBreaks
  ),

  globalTransition: '200ms linear all',
  fastTransition: '150ms linear all',

  h1: expandQueries(
    {
      fontSize: ['2.5rem', '3rem', '4.125rem'],
      fontWeight: 500,
      lineHeight: 1.1,
    },
    defaultExpansionBreaks
  ),
  h2: expandQueries(
    {
      fontSize: ['1.5rem', '2rem', '2.5rem'],
      fontWeight: 500,
      lineHeight: 1.2,
    },
    defaultExpansionBreaks
  ),
  h3: expandQueries(
    {
      fontSize: ['1.25rem', '1.25rem', '2rem'],
      fontWeight: 400,
      lineHeight: 1.4,
    },
    defaultExpansionBreaks
  ),
  h4: expandQueries(
    {
      fontSize: ['1.125rem', '1.5rem'],
      fontWeight: 400,
      lineHeight: 1.2,
    },
    defaultExpansionBreaks
  ),
  h5: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: 1,
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  paragraph: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.6,
  },
  lead: expandQueries(
    {
      fontSize: ['1rem', '1.125rem', '1.25rem'],
      fontWeight: 400,
      lineHeight: 1.6,
    },
    defaultExpansionBreaks
  ),
  smallcaps: {
    fontSize: '.875rem',
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  label: {
    fontSize: '16px', // this prevents zoom on iOS when select button is opened
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: '0.025em',
  },
}

export const globalStyles = [
  normalize,
  fonts,

  {
    [['*', '*::before', '*::after']]: {
      boxSizing: 'inherit',
    },

    html: {
      // Default font styles
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontSize: '87.5%',
      lineHeight: '1',
      // wordBreak: 'break-word',
      boxSizing: 'border-box',
      color: colors.kale,
      background: colors.miscBackground,

      // Improve browser font rendering
      textRendering: 'optimizeLegibility',
      fontFeatureSettings: "'kern' 1",
      fontKerning: 'normal',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',

      [breakpoints.medium]: {
        fontSize: '100%',
      },
    },

    [['h1', 'h2', 'h3', 'h4', 'h5', 'h6']]: {
      margin: 0,
      fontSize: 'inherit',
      color: colors.spinachDark,
    },

    p: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.6,
    },

    a: {
      color: 'inherit',
      textDecoration: 'none',
    },

    // Anchor links in long-form text
    '.link-underline, p a': {
      background: `linear-gradient(to bottom, ${colors.broccoli} 0%, ${colors.broccoli} 100%)`,
      backgroundPosition: '0 100%',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '4px 4px',
      color: colors.spinachDark,
      textDecoration: 'none',
      transition: 'background-size .2s',
      ':hover': {
        color: colors.spinachDark,
        backgroundSize: '4px 100%',
      },
    },

    blockquote: {
      fontSize: '1.5rem',
      borderLeft: '3px solid',
      fontStyle: 'italic',
      margin: '2rem 0',
      padding: '2.5rem',
      lineHeight: '1.25',
      backgroundColor: colors.broccoliLight,
    },

    img: {
      maxWidth: '100%',
    },

    svg: {
      display: 'block',
    },

    '.sr-only': {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px',
    },

    '::selection': {
      background: colors.spinachLight,
      color: colors.b90,
      textShadow: 'none',
    },

    'input:focus, select:focus, button:focus': {
      outline: '3px solid ' + colors.broccoli,
    },

    '.ReactModal__HiddenOverlay': {
      backgroundColor: 'none !important',
    },
    '.ReactModal__Overlay': {
      backgroundColor: 'rgba(20, 79, 70, .1) !important',
      transition: '.2s linear all',

      '&.ReactModal__Overlay--after-open': {
        backgroundColor: 'rgba(20, 79, 70, .8) !important',
        transition: '.2s linear all',
      },
    },

    '.ReactModal__Body--open': {
      overflow: 'hidden',
    },
    '.ReactModal__Content': {
      boxShadow: '0 0 35px 0 rgba(0, 0, 0, .2)',
    },
    '.slide-modal': {
      '.ReactModal__Content': {
        transform: 'translateX(100%)',
        transition: 'transform 150ms linear 25ms',
        '&.ReactModal__Content--after-open': {
          transform: 'translateX(0)',
        },
        '&.ReactModal__Content--before-close': {
          transform: 'translateX(100%)',
        },
      },
    },
    '.quick-view-modal': {
      '.ReactModal__Content': {
        transition: 'transform 150ms linear 25ms',
        minHeight: 'max-content',
        maxHeight: '95vh',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        [breakpoints.mediumDown]: {
          minHeight: '80vh',
        },
        [breakpoints.smallOnly]: {
          minHeight: '90vh',
        },
      },
      '.ReactModal__Overlay--after-open': {
        backgroundColor: 'rgba(0, 0, 0, .7) !important',
        transition: '.2s linear all',
      },
      '.ReactModal__Content > section': {
        padding: 0,
        height: '100%',
        '& > div': {
          width: '100%',
          columnGap: 0,
          height: '100%',
        },
      },
      '.ProductDisplayDetails': {
        padding: '25px 40px 0',
        h1: {
          [breakpoints.mediumDown]: {
            paddingRight: '20px',
          },
          [breakpoints.smallOnly]: {
            fontSize: '25px',
          },
        },
        '> div': {
          maxWidth: '100%',
          fontWeight: 500,
          p: {
            fontWeight: 500,
          },
        },
        [breakpoints.smallOnly]: {
          order: 1,
          padding: '15px 15px 0',
        },
      },
      '.ProductDisplayTags': {
        display: 'flex',
        flexWrap: 'wrap',
        '> div': {
          marginBottom: '5px',
          [breakpoints.smallOnly]: {
            p: {
              fontSize: '11px',
            },
          },
        },
      },
      '.ProductDisplayDescription a': {
        display: 'none',
      },
      '.ProductDisplaySlider, .ProductDisplaySlider > div': {
        height: '100%',
        [breakpoints.smallOnly]: {
          height: '240px',
        },
      },
      '.ProductImageCarouselSliderHolder': {
        height: '100%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        position: 'relative',
        backgroundColor: colors.white,
        img: {
          width: '100%',
          objectFit: 'contain',
          background: colors.white,
          height: 'calc(100% - 40px)',
          [breakpoints.smallOnly]: {
            height: '200px',
            marginTop: '6px',
          },
        },
        '&:after': {
          content: "''",
          display: 'block',
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '40px',
          background: colors.white,
          [breakpoints.smallOnly]: {
            bottom: '20px',
            background: 'red',
            display: 'none',
          },
        },
      },
      '.ProductDisplayForm': {
        maxWidth: '100%',
        padding: '0 40px 5px',
        [breakpoints.smallOnly]: {
          order: 2,
          padding: '0 15px 5px',
        },
      },
      '.ProductDisplayButton': {
        height: '55px',
        minHeight: 'inherit',
        padding: '1rem',
      },
      '.ProductDisplayFreeShipping': {
        margin: '5px 0 10px',
        [breakpoints.smallOnly]: {
          margin: '5px auto',
          width: '62%',
        },
      },
      '.ProductDisplayFactsLabel': {
        display: 'none',
      },
      '.ProductDisplayDiscountedPriceWrapper': {
        [breakpoints.smallOnly]: {
          fontSize: '13px',
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'flex-end',
          span: {
            margin: '5px 0 0 0',
          },
        },
      },
      '.ProductDisplayDiscountedPrice': {
        color: '#939d9b',
        fontSize: '14px',
        [breakpoints.smallOnly]: {
          fontSize: '12px',
        },
      },
      '.ProductDisplaySelector, .PurchaseTypeSelector': {
        minHeight: 'inherit',
        height: '55px',
        [breakpoints.mediumDown]: {
          height: 'auto',
          minHeight: '55px',
        },
        '& > button': {
          height: '100%',
          minHeight: 'inherit',
          [breakpoints.mediumDown]: {
            height: 'auto',
            minHeight: '55px',
            padding: '2px 1rem',
          },
          '&:first-child': {
            [breakpoints.mediumDown]: {
              marginBottom: 0,
            },
          },
        },
        select: {
          height: '100%',
          minHeight: 'inherit',
          lineHeight: '1',
        },
        '&--flex': {
          '&-auto': {
            [breakpoints.mediumDown]: {
              height: 'auto',
              flexDirection: 'column',
              button: {
                justifyContent: 'flex-start',
              },
              'button:first-child': {
                borderBottom: '1px solid currentColor',
              },
              'button:last-child': {
                borderLeft: '1px solid transparent',
              },
            },
          },
          '&-quantity': {
            [breakpoints.mediumDown]: {
              height: 'auto',
              borderLeft: '1px solid transparent',
              borderTop: '1px solid currentColor',
              'button:first-child': {
                borderTop: '1px solid transparent',
                borderBottom: '1px solid transparent',
              },
            },
          },
        },
      },
      '.ProductImageCarouselArrow': {
        top: 'inherit',
        bottom: '15px',
        width: 'max-content',
        [breakpoints.smallOnly]: {
          bottom: '10px',
        },
        svg: {
          height: '12px',
        },
        '&--right': {
          left: 'inherit',
          right: '50%',
          transform: 'translateX(50px)',
          '&:hover': {
            transform: 'translate(50px) scale(1.2)',
          },
        },
        '&--left': {
          right: 'inherit',
          left: '50%',
          transform: 'translateX(-50px)',
          '&:hover': {
            transform: 'translate(-50px) scale(1.2)',
          },
        },
      },
      '.ProductImageCarouselPagination': {
        bottom: '13px',
        right: 'inherit',
        left: '50%',
        margin: 0,
        textAlign: 'center',
        position: 'absolute',
        transform: 'translateX(-50%)',
        [breakpoints.smallOnly]: {
          bottom: '10px',
        },
      },
      '.PurchaseTypeSelectorItem': {
        [breakpoints.smallOnly]: {
          fontSize: '13px',
        },
      },
    },

    // A hack to fill the gap between HeroBanner and IntroBanner with white background
    '.HeroBanner + .IntroBanner::before': {
      backgroundColor: 'white',
      content: '""',
      width: '100%',
      height: '20rem',
      display: 'block',
      position: 'absolute',
      top: '-20rem',
      zIndex: '-1',
    },

    // Yotpo Widget Review rating width
    '.yotpo-display-wrapper .avg-score': {
      width: '71px!important',
      display: 'inline-block',
    },

    '.yotpo-display-wrapper .rating-stars-container': {
      marginLeft: '0!important',
    },

    '.yotpo-step-icon, .yotpo-widget-campaign-widget-icon-container svg, .yotpo-grid-vip-tier svg':
      {
        display: 'inline-block!important',
      },
  },
]
