import { Link as GatsbyLink } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const themes = {
  default: {},
  button: {
    '--buttonColor': theme.colors.w100,
    '--buttonBackground': theme.colors.spinachDark,
    '--buttonShadow': theme.colors.broccoliLight,
    color: 'var(--buttonColor)',
    background: 'var(--buttonBackground)',
    border: 0,
    display: 'inline-block',
    padding: '1rem',
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: 1,
    borderRadius: 3,
    textAlign: 'center',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: theme.globalTransition,
    userSelect: 'none',
    ':hover': {
      backgroundColor: 'var(--buttonBackground)',
    },
  },
}

const disabledThemes = {
  default: {},
  button: {
    ...themes.button,
    background: theme.colors.gray,
    pointerEvents: 'none',
  },
}

const Link = ({ children, to, theme, disabled, onClick, ...other }) => {
  if (other.href) {
    throw new Error("Use `to' attribute on Link not `href'!")
  }

  const css = {
    ...themes[theme || 'default'],
    ...(disabled ? disabledThemes[theme || 'default'] : {}),
  }
  const internalLink = /(^\/|^\#)(?!\/)/.test(to)
  const Tag = to ? (internalLink ? GatsbyLink : 'a') : 'button'
  const props = {
    [internalLink ? 'to' : 'href']: to,
    css,
    onClick: disabled ? disabledClickHandler : onClick,
    ...other,
  }

  if (to && !internalLink && !other.target) {
    props.target = '_blank'
    props.rel = 'noopener noreferrer'
  }

  return <Tag {...props}>{children}</Tag>
}

export default Link

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

const disabledClickHandler = e => {
  e.preventDefault()
}
