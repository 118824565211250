import BaseBlockContent from '@sanity/block-content-to-react'
import FeaturedProduct from 'components/FeaturedProduct'
import ImageUpload from 'components/ImageUpload'
import getYouTubeId from 'get-youtube-id'
import PropTypes from 'prop-types'
import React from 'react'
import YouTube from 'react-youtube'

import Bifold from './blocks/Bifold'

const defaultSerializers = {
  types: {
    bifold(props) {
      return <Bifold {...props.node} />
    },
    youtube: ({ node }) => {
      const { url } = node
      const id = getYouTubeId(url)
      return <YouTube videoId={id} />
    },
    imageUpload(props) {
      return <ImageUpload {...props.node} />
    },
    product(props) {
      return (
        <FeaturedProduct
          name={props.node.name}
          description={props.node.description}
          slug={props.node.slug}
          image={props.node.variants[0].images[0]}
        />
      )
    },
  },
}

const ContentBlock = ({ blocks, serializers = {} }) => (
  <BaseBlockContent blocks={blocks} serializers={{ ...defaultSerializers, ...serializers }} />
)

ContentBlock.propTypes = {
  blocks: PropTypes.array.isRequired,
  serializers: PropTypes.object,
}

export default ContentBlock
