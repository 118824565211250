import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const CREATE_CART = gql`
  ${CORE_FRAGMENTS}
  mutation CREATE_CART($input: CartInput) {
    cartCreate(input: $input) {
      cart {
        ...CartResponse
      }
      userErrors {
        field
        message
      }
    }
  }
`
