import { graphql, useStaticQuery } from 'gatsby'
import useCart from 'hooks/useCart'
import hexToRgb from 'lib/hexToRgb'
import React from 'react'
import theme from 'styles/theme'

const ShippingProgressBar = () => {
  const { settings } = useStaticQuery(graphql`
    {
      settings: sanityShippingSettings {
        freeShippingMinimum
        freeShippingMessage
      }
    }
  `)
  const minimum = settings.freeShippingMinimum
  const { subtotalPrice } = useCart()
  const subtotal = parseFloat(subtotalPrice)
  const progressPercent = (subtotal / minimum) * 100

  return (
    <>
      {minimum > 0 ? (
        <div
          css={{
            position: 'relative',
            background:
              subtotal > minimum
                ? theme.colors.broccoliLight
                : hexToRgb(theme.colors.blueberryLight, 0.5),
            fontSize: '.875rem',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: 7,
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          {subtotal < minimum && (
            <div
              css={{
                position: 'absolute',
                width: `${progressPercent}%`,
                top: 0,
                left: 0,
                height: '100%',
                background: theme.colors.blueberryLight,
                zIndex: 1,
              }}
            />
          )}

          <div css={{ position: 'relative', zIndex: 2 }}>
            {subtotal < 1
              ? `Free shipping on orders over $${minimum.toFixed(0)}`
              : subtotal < minimum
              ? `$${(minimum - subtotal).toFixed(2)} away from free shipping`
              : `Nice! You get free shipping`}
          </div>
        </div>
      ) : (
        <div
          css={{
            position: 'relative',
            backgroundColor: theme.colors.broccoliLight,
            fontSize: '.875rem',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: 7,
            borderRadius: 3,
            overflow: 'hidden',
          }}
        >
          {settings.freeShippingMessage}
        </div>
      )}
    </>
  )
}

export default ShippingProgressBar
