import Icon from 'components/Icon'
import Link from 'components/Link'
import DiscountInput from 'components/cart/DiscountInput'
import ShippingProgressBar from 'components/cart/ShippingProgressBar'
import useCart from 'hooks/useCart'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const CartCalculations = ({ className }) => {
  const cart = useCart()

  const sendTrackingToRefersion = checkout_token => {
    const rfsn = {
      cart: checkout_token,
      id: localStorage.getItem('rfsn_v4_id'),
      url: window.location.href,
      aid: localStorage.getItem('rfsn_v4_aid'),
      cs: localStorage.getItem('rfsn_v4_cs'),
    }
    r.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs)
  }

  const handleCheckout = () => {
    if (window && window.r) sendTrackingToRefersion(cart.token)
    window.location.href = cart.checkoutUrl
  }

  return (
    <div
      css={{
        width: '100%',
        maxWidth: '480px',
        color: theme.colors.spinachDark,
        backgroundColor: theme.colors.w100,
        padding: '1.25rem',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '1.25rem',
        [theme.mediumDown]: {
          maxWidth: 'unset',
        },
      }}
      className={className}
    >
      {(!cart.discountCode || !cart?.appliedDiscount?.applicable) && (
        <DiscountInput
          initialValue={cart.discountCode || ''}
          error={cart.appliedDiscount?.nonApplicableReason}
          applyDiscount={cart.applyDiscount}
          removeDiscount={cart.removeDiscount}
          cartEmpty={cart.lineItems?.length === 0}
        />
      )}

      <CalculatedRow
        label='Subtotal'
        value={cart.lineItems?.length > 0 ? `$${cart.subtotalPrice}` : '—'}
      />

      {cart.totalTaxAmount && <CalculatedRow label='Tax' value={`$${cart.totalTaxAmount}`} />}

      {cart.discountCode && cart?.appliedDiscount?.applicable && (
        <AppliedDiscount
          discountCode={cart?.discountCode}
          appliedDiscount={cart?.appliedDiscount}
          removeDiscount={cart.removeDiscount}
        />
      )}

      <div
        css={{
          ...theme.h4,
          fontWeight: theme.fontWeight.bold,
          color: theme.colors.spinach,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>Total</div>
        <div>{cart.lineItems?.length > 0 ? `$${cart.totalPrice}` : '—'}</div>
      </div>

      <ShippingProgressBar />

      <Link
        theme='button'
        css={{
          '--buttonBackground': theme.colors.spinachDark,
          '--buttonColor': theme.colors.w100,
          padding: '1.5rem',
          fontSize: 18,
          ':hover': {
            backgroundColor: theme.colors.spinach,
          },
        }}
        onClick={handleCheckout}
        disabled={cart.lineItems.length === 0}
      >
        Checkout &rarr;
      </Link>
    </div>
  )
}

export default CartCalculations

CartCalculations.propTypes = {
  className: PropTypes.string,
}

const AppliedDiscount = ({ discountCode, appliedDiscount, removeDiscount }) => (
  <CalculatedRow
    label={
      <div css={{ display: 'flex', alignItems: 'center' }}>
        <span css={{}}>Discount</span>

        <span css={{ marginLeft: '1rem' }}>{discountCode}</span>

        <Icon.CloseCircle
          onClick={removeDiscount}
          width={23}
          css={{
            opacity: '0.5',
            cursor: 'pointer',
            transition: 'opacity 250ms',
            ':hover': {
              opacity: 0.8,
            },
          }}
        />
      </div>
    }
    value={`-$${appliedDiscount.value}`}
  />
)

AppliedDiscount.propTypes = {
  discountCode: PropTypes.string.isRequired,
  appliedDiscount: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
  removeDiscount: PropTypes.func.isRequired,
}

const CalculatedRow = ({ label, value }) => (
  <div
    css={{
      fontSize: '1rem',
      fontWeight: theme.fontWeight.bold,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div>{label}</div>
    <div>{value}</div>
  </div>
)

CalculatedRow.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
}
