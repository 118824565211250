import LoadingIndicator from 'components/LoadingIndicator'
import CartCalculations from 'components/cart/CartCalculations'
import EmptyCart from 'components/cart/EmptyCart'
import { CartHeading } from 'components/cart/Heading'
import LineItem from 'components/cart/LineItem'
import useCart from 'hooks/useCart'
import { fireAnalyticsEvent, fireElevarAnalyticsEventGeneric } from 'lib/analytics'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import theme from 'styles/theme'

const Cart = ({ styles }) => {
  const cart = useCart()

  useEffect(() => {
    console.log('Cart Viewed', cart)
    fireAnalyticsEvent({ event: 'viewCart' })

    // Elevar Analytics - View Cart - dl_view_cart

    // Map the cart.lineItems to the elevarProductArr
    const impressionsArr = cart?.lineItems?.map((item, index) => {
      const variant_id = item?.variantId?.split('/')?.[4]

      return {
        id: item.variantSku || '', // SKU
        name: item.title || '', // Product title
        brand: 'OWYN',
        category: '',
        variant: item.variantTitle || '',
        price: item.price.toString() || '',
        quantity: item.quantity.toString() || '',
        list: '', // The list the product was discovered from
        product_id: variant_id || '', // The product_id is the variant_id
        variant_id: variant_id || '',
        compare_at_price: '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
        image: item.image || '', // If available, otherwise use an empty string
        inventory: '',
        position: index + 1,
      }
    })

    const elevarPayloadCore = {
      actionField: {
        list: 'Shopping Cart',
      },
      impressions: impressionsArr,
    }

    fireElevarAnalyticsEventGeneric('dl_view_cart', elevarPayloadCore)
  }, [])

  useEffect(() => {
    if (cart.isOpen) {
    }
  }, [cart.isOpen])

  return (
    <div className='Cart' css={styles}>
      {cart.loading && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          }}>
          <LoadingIndicator />
        </div>
      )}

      <div
        className='CartContainer'
        css={{
          opacity: cart.loading ? '.3' : '1',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
        }}>
        <div
          className='CartItems'
          css={{
            flexGrow: '1',
            color: theme.colors.kale,
            borderBottom: `1px solid ${theme.colors.spinach}`,
          }}>
          <CartHeading />

          {cart.lineItems.map(item => (
            <LineItem key={item.variantId} cart={cart} {...item} />
          ))}

          {cart.lineItems.length === 0 && <EmptyCart />}
        </div>

        <CartCalculations className='CartCalculations' css={{ alignSelf: 'flex-end' }} />
      </div>
    </div>
  )
}

Cart.propTypes = {
  styles: PropTypes.any,
}

export default Cart
