import theme from 'styles/theme'

export default {
  mobile: {
    '.LineItem': {
      backgroundColor: theme.colors.white,
      boxShadow: theme.boxShadow.slight,
      margin: '1rem 0',
      overflow: 'hidden',

      '> div': {
        marginLeft: '7rem',
      },

      img: {
        display: 'block',
        objectFit: 'contain',
        float: 'left',
      },

      '.ProductTitle': {
        position: 'relative',
        h3: {
          ...theme.h5,
          lineHeight: '1.2',
          color: theme.colors.b90,
          padding: '1rem 2rem 1rem 0',
          margin: 0,
        },
      },

      '.RemoveAction': {
        position: 'absolute',
        top: '.5rem',
        right: '.5rem',

        '&__text': {
          display: 'none',
        },
      },

      '.PriceColumn': {
        display: 'none',
      },

      '.QuantitySelector': {
        '> button': {
          padding: '.5rem 0',
        },
      },

      '.SubscriptionDetails': {
        borderTop: `1px solid ${theme.colors.broccoliLight}`,
        padding: '1rem 0',
        marginRight: '1rem',
      },
    },

    '.label': {
      display: 'none',
    },
  },
  desktop: {
    '.CartContainer': {
      padding: '6.5rem 1rem',
    },

    '.LineItem': {
      display: 'grid',
      gridTemplateColumns: '6.25rem 2fr 1fr 2fr',
      gridTemplateRows: '1fr',
      gap: '.25rem 1rem',
      justifyItems: 'stretch',
      alignItems: 'center',

      img: {
        gridColumn: '1',
      },

      '.ProductTitle': {
        gridColumn: '2',
        h3: {
          color: theme.colors.spinach,
        },
      },

      '.RemoveAction__icon': {
        display: 'none',
      },

      '.PriceColumn': {
        gridColumn: '3',
      },

      '.TotalColumn': {
        gridColumn: '5',
        justifySelf: 'flex-end',
        color: theme.colors.kale,
      },

      '.SubscriptionDetails, .SubscribeButton': {
        gridColumn: '2 / 5',
      },

      '.QuantitySelector': {
        border: '1px solid currentColor',
        borderRadius: 2,
        margin: 0,
        gridColumn: '4',
        gridRow: '1',
        maxWidth: '12.5rem',
        '> button': {
          padding: '1rem',
        },
      },

      '.QuantityWrapper': {
        margin: 0,
        flexDirection: 'row-reverse',
      },
    },

    '.CartCalculations': {
      padding: '2.5rem',
    },
  },
}
