import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const ContentContainer = ({ max = theme.maxContainerWidth, children, styles }) => (
  <div
    className='ContentContainer'
    css={{
      width: theme.maxContainerPercent,
      maxWidth: max,
      marginLeft: 'auto',
      marginRight: 'auto',
      ...styles,
    }}
  >
    {children}
  </div>
)

export default ContentContainer

ContentContainer.propTypes = {
  max: PropTypes.string,
  children: PropTypes.node.isRequired,
  wrapperStyles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  className: PropTypes.string,
  styles: PropTypes.object,
}
