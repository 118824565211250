exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-account-invite-jsx": () => import("./../../../src/pages/account-invite.jsx" /* webpackChunkName: "component---src-pages-account-invite-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-my-account-index-jsx": () => import("./../../../src/pages/my-account/index.jsx" /* webpackChunkName: "component---src-pages-my-account-index-jsx" */),
  "component---src-pages-my-account-update-jsx": () => import("./../../../src/pages/my-account/update.jsx" /* webpackChunkName: "component---src-pages-my-account-update-jsx" */),
  "component---src-pages-reset-jsx": () => import("./../../../src/pages/reset.jsx" /* webpackChunkName: "component---src-pages-reset-jsx" */),
  "component---src-pages-sign-up-jsx": () => import("./../../../src/pages/sign-up.jsx" /* webpackChunkName: "component---src-pages-sign-up-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/CategoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-collection-template-jsx": () => import("./../../../src/templates/CollectionTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/PageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-list-template-jsx": () => import("./../../../src/templates/PostListTemplate.jsx" /* webpackChunkName: "component---src-templates-post-list-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/PostTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/ProductTemplate.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */)
}

