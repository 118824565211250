import Icon from 'components/Icon'
import Cart from 'components/cart'
import CartStyles from 'components/cart/styles'
import useCart from 'hooks/useCart'
import React from 'react'
import Modal from 'react-modal'
import theme from 'styles/theme'

import hexToRgb from '../lib/hexToRgb'

const borderColor = hexToRgb(theme.colors.broccoliLight, 0.5)

Modal.setAppElement('body')

export const CartButton = () => {
  const cart = useCart()

  return (
    <>
      <a
        onClick={e => cart.open()}
        css={{
          ...theme.h4,
          textDecoration: 'none',
          color: theme.colors.spinachDark,
          backgroundColor: cart.totalCount > 0 ? theme.colors.broccoliLight : 'transparent',
          display: 'inline-block',
          width: '3rem',
          height: '3rem',
          lineHeight: '3rem',
          textAlign: 'center',
          borderRadius: '999px',
          margin: '.5rem',
          padding: '.5em',
          boxShadow: '0 0 0 0' + borderColor,
          transition: '150ms linear all',
          position: 'relative',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: theme.colors.broccoliLight,
            boxShadow: cart.totalCount > 0 ? '0 0 0 .5rem ' + borderColor : 'none',
          },
        }}
      >
        <Icon.CartOutline
          width={24}
          height={24}
          css={{ color: theme.colors.spinachDark }}
          aria-hidden='true'
        />
        <span className='sr-only'>Cart</span>
        {!cart.loading && cart.totalCount > 0 && (
          <span
            css={{
              fontSize: '14px',
              fontWeight: 'bolder',
              color: theme.colors.kale,
              backgroundColor: theme.colors.strawberry,
              width: 20,
              height: 20,
              lineHeight: '22px',
              borderRadius: 999,
              position: 'absolute',
              top: '-1px',
              left: '-12px',
            }}
          >
            {cart.totalCount}
          </span>
        )}
      </a>

      <Modal
        style={{
          overlay: {
            zIndex: theme.zIndex.modal,
          },
          content: {
            right: 0,
            top: 0,
            bottom: 0,
            left: 'auto',
            maxWidth: '480px',
            width: '100vw',
            padding: 0,
            border: 0,
            borderRadius: 0,
            backgroundColor: theme.colors.creamless,
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        portalClassName='slide-modal'
        closeTimeoutMS={300}
        isOpen={cart.isOpen}
        onRequestClose={() => cart.close()}
      >
        <div
          css={{
            backgroundColor: theme.colors.white,
            padding: '0 1.5rem',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <div
            css={{
              ...theme.h6,
              lineHeight: '1',
              color: theme.colors.spinachDark,
              backgroundColor: theme.colors.white,
              borderBottom: '1px solid currentColor',
              position: 'relative',
            }}
          >
            <a
              onClick={e => cart.close()}
              css={{
                fontWeight: theme.fontWeight.bold,
                display: 'inline-flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '.75rem 0',
                transition: theme.globalTransition,
                ':hover': {
                  color: theme.colors.spinach,
                },
              }}
            >
              <Icon.ArrowBack
                width={24}
                height={24}
                css={{
                  marginRight: '.5rem',
                }}
                aria-hidden='true'
              />
              Continue Shopping
            </a>
          </div>
        </div>

        <Cart
          styles={{
            ...CartStyles.mobile,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            '.CartItems': {
              overflow: 'auto',
              padding: '0 1.5rem',
              border: 0,
            },
            '.CartHeading': {
              display: 'none',
            },
            '.CartCalculations': {
              position: 'sticky',
              bottom: 0,
              boxShadow: '0 0 40px 0 rgba(0, 0, 0, .1)',
            },
          }}
        />
      </Modal>
    </>
  )
}
