import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const UPDATE_LINE_ITEM = gql`
  ${CORE_FRAGMENTS}
  mutation UPDATE_LINE_ITEM($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...CartResponse
      }
      userErrors {
        field
        message
      }
    }
  }
`
