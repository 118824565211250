import { createSlice } from '@reduxjs/toolkit'
import { clearLocalAuthCredentials, localAuthenticate } from 'utils/auth'

const defaultState = {
  currentUser: null,
  isAuthenticated: localAuthenticate(),
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: defaultState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload
    },
    setLoggedInUser: state => {
      state.isAuthenticated = true
    },
    logout: state => {
      clearLocalAuthCredentials()
      state.currentUser = null
      state.isAuthenticated = false
    },
  },
  extraReducers: () => {},
})

export const authReducer = authSlice.reducer
export const authActions = {
  ...authSlice.actions,
}
