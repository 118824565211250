import React, { useEffect, useState } from 'react'

/*
 * Two-pass Render
 *
 * This utility is used to circumvent React hydration issues
 * when discrepancies exist between the SSR version and the
 * browser rendered version. See Gatsby writeup:
 *
 * https://github.com/gatsbyjs/gatsby/discussions/17914
 */

// eslint-disable-next-line react/display-name
const twoPassRender = SomeComponent => props => {
  const [isClient, setIsClient] = useState(false)
  useEffect(() => setIsClient(true), [])

  return <SomeComponent key={`render-on-${isClient ? 'client' : 'server'}`} {...props} />
}

export default twoPassRender
