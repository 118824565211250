import Icon from 'components/Icon'
import InvisibleButton from 'components/InvisibleButton'
import twoPassRender from 'lib/twoPassRender'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const PurchaseTypeSelector = twoPassRender(
  ({
    activeType,
    allowSubscriptions,
    subscriptionDescription,
    dispatch,
    interval,
    intervalOptions = [14, 30, 60],
    intervalUnit = 'days',
    className,
  }) => (
    <>
      <div
        className='PurchaseTypeSelector'
        css={{
          ...theme.label,
          userSelect: 'none', // prevent accidental highlighting
          minHeight: 64,
          [theme.smallOnly]: {
            '> button:first-child': {
              borderBottom: '1px solid',
            },
          },
          [theme.medium]: {
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'space-between',
          },
          [theme.mediumOnly]: {
            flexWrap: 'wrap',
            '> button:first-child': {
              borderBottom: '1px solid',
            },
          },
        }}
        className={className}
      >
        <PurchaseTypeOption
          onClick={() => dispatch({ change: { subscription: false } })}
          active={activeType === 'otp'}
        >
          <span css={{ textAlign: 'left' }}>One-time Purchase</span>
        </PurchaseTypeOption>

        <PurchaseTypeOption
          onClick={() => dispatch({ change: { subscription: true } })}
          disabled={!allowSubscriptions}
          active={activeType === 'sub'}
        >
          <span css={{ textAlign: 'left' }}>
            Subscribe
            <span
              css={{
                display: 'block',
                fontSize: '.75rem',
                color: theme.colors.spinach,
                marginTop: '.25rem',
              }}
            >
              {subscriptionDescription.split('\n').map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </span>
          </span>
        </PurchaseTypeOption>
      </div>

      {activeType === 'sub' && (
        <>
          <div
            css={{
              padding: '4px 12px',
              '.item': {
                display: 'flex',
                alignItems: 'center',
                '&:first-child': {
                  marginBottom: '12px',
                },
                svg: {
                  width: '20px',
                  height: '20px',
                  marginRight: '4px',
                },
              },
            }}
          >
            <div className='item PurchaseTypeSelectorItem'>
              <Icon.SkipTwoArrow />
              Skip shipments or cancel at any time
            </div>
            <div className='item PurchaseTypeSelectorItem'>
              <Icon.UpdateSubscription />
              Update the quantity, swap, or add new products
            </div>
          </div>
          <div
            css={{
              position: 'relative',
            }}
            className={className}
          >
            <select
              id='interval-select'
              css={{
                ...theme.label,
                display: 'block',
                width: '100%',
                appearance: 'none',
                background: 'transparent',
                color: 'inherit',
                border: 0,
                padding: '0 1rem',
                minHeight: '64px',
                lineHeight: '64px',
              }}
              onChange={e =>
                dispatch({
                  change: {
                    interval: parseInt(e.target.value, 10),
                  },
                })
              }
              value={interval}
            >
              {intervalOptions.map(value => (
                <option key={value} value={value}>
                  {value} {intervalUnit}
                  {value === 1 ? '' : 's'}
                </option>
              ))}
            </select>

            <Icon.DropdownArrow
              width={24}
              css={{
                pointerEvents: 'none',
                position: 'absolute',
                right: '1rem',
                top: '50%',
                transform: 'translateY(-50%)',
              }}
            />
          </div>
        </>
      )}
    </>
  )
)

export default PurchaseTypeSelector

PurchaseTypeSelector.propTypes = {
  activeType: PropTypes.oneOf(['otp', 'sub']).isRequired,
  allowSubscriptions: PropTypes.bool,
  subscriptionDescription: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  interval: PropTypes.number.isRequired,
  intervalOptions: PropTypes.arrayOf(PropTypes.number),
  intervalUnit: PropTypes.string,
  className: PropTypes.string,
}

export const PurchaseTypeOption = ({
  active,
  onClick,
  disabled,
  className,
  children,
  setOnClickToRadio,
}) => (
  <InvisibleButton
    onClick={disabled || setOnClickToRadio ? null : onClick}
    className={className}
    css={{
      height: 64,
      width: '100%',
      padding: '0 1rem',
      '&:disabled': {
        opacity: '0.5',
        cursor: 'not-allowed',
      },
    }}
    disabled={disabled}
  >
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <RadioIndicator
        onClick={setOnClickToRadio ? onClick : null}
        active={active}
        css={{
          marginRight: '0.5em',
          cursor: setOnClickToRadio ? 'pointer' : 'initial',
        }}
      />{' '}
      {children}
    </div>
  </InvisibleButton>
)

PurchaseTypeOption.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  setOnClickToRadio: PropTypes.bool,
}

const RadioIndicator = ({ active = false, className, onClick }) => (
  <svg viewBox='0 0 20 20' width='20' className={className} onClick={onClick}>
    {active && <circle cx='10' cy='10' fill='currentColor' r='4.5'></circle>}
    <circle cx='10' cy='10' r='8.25' fill='none' strokeWidth='1.8' stroke='currentColor' />
  </svg>
)

RadioIndicator.propTypes = {
  active: PropTypes.bool.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
