import { useLazyQuery } from '@apollo/client'
import { GET_CUSTOMER } from 'lib/graphql/customer'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authActions } from 'store/auth/slice'
import { getAccessToken } from 'utils/auth'

const CustomerInfoFetcher = props => {
  const dispatch = useDispatch()
  const [getUser] = useLazyQuery(GET_CUSTOMER, {
    onCompleted: data => {
      if (data.customer) {
        return dispatch(authActions.setUser(data.customer))
      }
      dispatch(authActions.logout())
    },
  })
  useEffect(() => {
    getUser({
      variables: {
        customerAccessToken: getAccessToken(),
      },
    })
  }, [])

  return <>{props.children}</>
}

export default CustomerInfoFetcher
