import { gql } from '@apollo/client'

export const CORE_FRAGMENTS = gql`
  fragment CartResponse on Cart {
    id
    checkoutUrl
    discountCodes {
      code
      applicable
    }
    estimatedCost {
      subtotalAmount {
        amount
      }
      totalAmount {
        amount
      }
      totalTaxAmount {
        amount
      }
    }
    lines(first: 100) {
      edges {
        node {
          id
          merchandise {
            ... on ProductVariant {
              id
              title
              sku
              image {
                src
              }
              product {
                id
                title
              }
              priceV2 {
                amount
              }
            }
          }
          quantity
          estimatedCost {
            totalAmount {
              amount
            }
            subtotalAmount {
              amount
            }
          }
          discountAllocations {
            discountedAmount {
              amount
            }
          }
          sellingPlanAllocation {
            priceAdjustments {
              compareAtPrice {
                amount
              }
              perDeliveryPrice {
                amount
              }
              price {
                amount
              }
            }
            sellingPlan {
              id
              name
              priceAdjustments {
                adjustmentValue {
                  ... on SellingPlanPercentagePriceAdjustment {
                    adjustmentPercentage
                  }
                  ... on SellingPlanFixedAmountPriceAdjustment {
                    adjustmentAmount {
                      amount
                      currencyCode
                    }
                  }
                  ... on SellingPlanFixedPriceAdjustment {
                    price {
                      amount
                      currencyCode
                    }
                  }
                }
              }
              options {
                name
                value
              }
              recurringDeliveries
            }
          }
        }
      }
    }
  }
`
