import { createSlice } from '@reduxjs/toolkit'

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    nodes: [],
    activeVariantId: null, // TODO: do move to different slice perhaps
  },
  reducers: {
    setProducts: (state, action) => {
      state.nodes = [...action.payload]
    },
    setActiveVariantId: (state, action) => {
      state.activeVariantId = action.payload
    },
  },
})

export const productsReducer = productsSlice.reducer
export const productsActions = {
  ...productsSlice.actions,
}
