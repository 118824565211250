import { gql } from '@apollo/client'

import { CORE_FRAGMENTS } from './fragments'

export const ADD_CART_ITEM = gql`
  ${CORE_FRAGMENTS}
  mutation ADD_CART_ITEM($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...CartResponse
      }
      userErrors {
        field
        message
      }
    }
  }
`
